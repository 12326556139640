import { gsap  } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

document.addEventListener('DOMContentLoaded', () => {
    gsap.registerPlugin(ScrollTrigger);
    
    window.anims = () => {       
        let hero = document.querySelector('.c-hero')
        if (hero) {
            let tl = gsap.timeline();
            tl.from(hero, {
                duration: .5,
                autoAlpha: 0,
            })
            
            ScrollTrigger.matchMedia({
                "(min-width: 641px)": () => {
                    tl.to(hero.querySelector('img'), {
                        duration: 1,
                        height: '100%',
                    }, '+=.8')
                    
                    .to(hero, {
                        duration: 1,
                        height: 'auto',
                    }, '-=1')                    
                }
            });
            
            tl.from(document.querySelector('.o-bg'), {
                        duration: 1,
                        autoAlpha: 0,
                    }, '-=1')
            
            .from(document.querySelector('.c-intro'), {
                y: 40,
                duration: 1.5,
                autoAlpha: 0,
                ease: 'elastic.out(1, .8)',
                onComplete: function() {
                    ScrollTrigger.refresh();
                    apply();
                }
            }, '-=.2')            
        }
        
        const apply = () => {
            let apply_btn = document.querySelector('.js-applyBtn');
            let offset = 40;
            window.innerWidth <= 1024 ? offset = 30 : false;

            if (apply_btn) {
                //ScrollTrigger.matchMedia({
                    //"(min-width: 769px)": () => {
                        gsap.to(apply_btn, {
                            scrollTrigger: {
                                trigger: '.o-container',
                                pin: '.js-applyBtn',
                                pinSpacing: false,
                                start: () => document.querySelector('.c-hero').offsetHeight - 
                                             apply_btn.offsetHeight - offset + 'px top',
                            },
                        });
                    //}
                //});
                
                ScrollTrigger.matchMedia({
                    "(max-width: 640px)": () => {
                        gsap.to(apply_btn, {
                            scrollTrigger: {
                                trigger: '.o-container',
                                start: () => document.querySelector('.c-hero').offsetHeight - 
                                             apply_btn.offsetHeight - offset + 'px top',
                                toggleActions: "play complete complete reverse",
                            },
                            opacity: 1
                        });
                    }
                });
            }
        }
        
        const beneftis = document.querySelector('.c-benefits');
        if (beneftis) {
            ScrollTrigger.batch(beneftis.querySelectorAll('li'), {
                onEnter: elements => {
                  gsap.from(elements, {
                    autoAlpha: 0,
                    y: 60,
                    stagger: {
                        from: "left",
                        axis: "x",
                        amount: 0.3
                    }
                  });
                },
                once: true
            });
        }
             
        const positions = document.querySelector('.c-positions');
        if (positions) {

/*
            ScrollTrigger.create({
                trigger: positions.querySelector('h2'),
                start: 'top center',
                onEnter: function() {
                    positions.classList.add('is-active')
                },
                once: true
            });
*/
            
            ScrollTrigger.batch(positions.querySelectorAll('li'), {
                onEnter: elements => {
                  gsap.from(elements, {
                        autoAlpha: 0,
                        y: 60,
                        stagger: {
                            from: "left",
                            axis: "x",
                            amount: 0.3
                        }
                    });
                },
                once: true
            });
            
            

            
/*
            gsap.to(positions, {
                scrollTrigger: {
                    trigger: positions.querySelector('h2'),
                    start: 'top top',
                    pin: true,
                    scrub: 1,
                    markers: true
                },
            });
*/

        }
        
        gsap.utils.toArray(".a-fadeInChildren > *").forEach(function(section) {
            gsap.from(section, {
                scrollTrigger: {
                    trigger: section,
                    start: '-50px bottom',
                    toggleActions: "play complete complete complete",
                },
                autoAlpha: 0,
                duration: 1,
                y: 50
            });
        });
    };

}, false)
